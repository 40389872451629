@import ../../styles/helpers

.convertpage
    display: flex
    flex-direction: column
    padding: 40px
    background-color: #f9f9f9
    font-family: Arial, sans-serif
    max-width: 1200px
    margin: 0 auto
    +dark
        background-color: #2c2c2c
        color: #f1f1f1

.convertheader
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 20px
    
.converttitle
    font-size: 36px
    font-weight: bold
    color: #333
    +dark
        color: #f1f1f1

.buttongroup
    display: flex
    gap: 15px

.convertbtn
    padding: 10px 20px
    font-size: 14px
    background-color: #2d87f0
    color: white
    border: none
    border-radius: 5px
    cursor: pointer
    +dark
        background-color: #1d6fb0
    &:hover
        background-color: #1d6fb0
        +dark
            background-color: #187bb5

.convertbody
    display: flex
    gap: 30px
    justify-content: space-between

.leftsection
    flex: 1
    max-width: 45%

.lefttitle
    font-size: 28px
    font-weight: bold
    color: #333
    +dark
        color: #f1f1f1

.leftsubtitle
    font-size: 18px
    color: #777
    margin-top: 10px
    +dark
        color: #aaa

.leftdescription
    font-size: 14px
    color: #555
    margin-top: 20px
    +dark
        color: #ddd

.rightsection
    flex: 1
    background-color: #ffffff
    padding: 20px
    border-radius: 8px
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1)
    max-width: 50%
    +dark
        background-color: #333333
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3)

.convertheaderright
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 20px

.headerleft
    display: flex
    align-items: center

.headertitle
    font-size: 22px
    font-weight: bold
    color: #333
    +dark
        color: #f1f1f1

.feestatus
    font-size: 14px
    color: #22767d
    margin-left: 15px
    +dark
        color: #22767d

.headerright
    font-size: 16px
    color: #1d6fb0
    cursor: pointer
    +dark
        color: #4a90e2

.inputcontainer
    display: flex
    flex-direction: column
    gap: 20px

.inputgroup
    display: flex
    flex-direction: column
    gap: 10px

.inputgroup label
    font-size: 14px
    color: #333
    +dark
        color: #f1f1f1

.inputbox
    display: flex
    align-items: center
    gap: 10px
    border: 1px solid #ddd
    padding: 8px 12px
    border-radius: 5px
    +dark
        background-color: #444444
        border-color: #666

.coinselect
    display: flex
    align-items: center
    gap: 8px

.coinselect img
    width: 20px
    height: 20px

.inputbox input
    flex: 1
    border: none
    outline: none
    padding: 5px
    font-size: 14px
    +dark
        background-color: #444444
        color: #f1f1f1

.balanceinfo
    display: flex
    justify-content: space-between

.maxbtn
    font-size: 14px
    color: #1d6fb0
    cursor: pointer
    +dark
        color: #4a90e2

.convertbutton
    padding: 15px
    font-size: 16px
    background-color: #22767d
    color: white
    border: none
    border-radius: 8px
    cursor: pointer
    margin-top: 30px
    +dark
        background-color: #22767d
    &:hover
        background-color: #22767d
        +dark
            background-color: #22767d

.convertbutton
    &:hover
        background-color: #22767d
        +dark
            background-color: #22767d

.faqsection
    margin-top: 40px

.faqheader
    display: flex
    justify-content: space-between
    align-items: center

.faqheader h2
    font-size: 20px
    font-weight: bold
    +dark
        color: #f1f1f1

.faqmorelink
    font-size: 14px
    color: #1d6fb0
    text-decoration: none
    +dark
        color: #4a90e2
    &:hover
        text-decoration: underline
        +dark
            text-decoration: none

.lefttextarea
    background-color: #f9f9f9
    padding: 20px
    border-radius: 8px
    max-width: 600px
    margin: 0 auto
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1)
    +dark
        background-color: #333333
        color: #f1f1f1
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3)

.title
    font-size: 36px
    font-weight: bold
    color: #333
    margin-bottom: 10px
    text-align: left
    +dark
        color: #f1f1f1

.subtitle
    font-size: 18px
    color: #777
    margin-bottom: 20px
    text-align: left
    +dark
        color: #aaa

.des
    font-size: 14px
    color: #555
    line-height: 1.6
    text-align: left
    +dark
        color: #ddd

.faqcontent
    padding: 20px
    background-color: #f9f9f9
    border-radius: 8px
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1)
    margin-top: 20px
    +dark
        background-color: #444444
        color: #f1f1f1

.commonfaqbox
    max-width: 800px
    margin: 0 auto

.commonfaqtitle
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 20px

.commonfaqtitle h2
    font-size: 24px
    font-weight: bold
    color: #333
    +dark
        color: #f1f1f1

.faqmore a
    display: flex
    align-items: center
    font-size: 14px
    color: #007bff
    +dark
        color: #4a90e2

.faqmore a:hover
    text-decoration: underline
    +dark
        text-decoration: none

.faqmore img
    margin-left: 8px
    transition: transform 0.2s ease
    +dark
        filter: brightness(0.8)

.faqmore a:hover img
    transform: translateX(4px)
    +dark
        transform: translateX(4px)

.commonfaqlist
    margin-bottom: 10px
    padding: 10px 0
    border-bottom: 1px solid #eee
    +dark
        border-bottom: 1px solid #666

.commonfaqq
    display: flex
    justify-content: space-between
    align-items: center
    cursor: pointer
    font-size: 16px
    font-weight: 500
    color: #333
    +dark
        color: #f1f1f1

.commonfaqlisttext
    display: none
    padding: 10px 20px
    background-color: #f1f1f1
    border-radius: 4px
    +dark
        background-color: #666666
        color: #f1f1f1

.commonfaqlisttext .text
    font-size: 14px
    color: #555
    line-height: 1.6
    +dark
        color: #ddd

.disabledButton 
    background-color: gray
    color: white
    cursor: not-allowed
    padding: 15px
    font-size: 16px
    border: none
    border-radius: 8px
    margin-top: 30px
    +dark
        background-color: #gray
    &:hover
        background-color: #gray
        +dark
            background-color: #gray

.converthistory
    color: #22767d
    text-decoration: underline
   
.faqcontainer
    margin: 20px 0
    font-family: Arial, sans-serif
    +dark
        background-color: #2c2c2c
        color: #f1f1f1

.faqitem 
    background: #fff
    border: 1px solid #ddd
    border-radius: 5px
    margin-bottom: 10px
    overflow: hidden
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1)
    transition: all 0.3s ease
    +dark
        background-color: #2c2c2c
        color: #f1f1f1
    &:hover
        background-color: #2c2c2c

.faqtitle
    display: flex
    justify-content: space-between
    align-items: center
    padding: 15px 20px
    background-color: #f7f7f7
    cursor: pointer
    font-size: 1.1rem
    font-weight: 600
    transition: background-color 0.3s ease
    +dark
        background-color: #2c2c2c
        color: #f1f1f1

.faqtitle:hover
    background-color: #e0e0e0
    +dark
        background-color: #2c2c2c
        color: #f1f1f1s

.faqicon
    font-size: 1.5rem
    color: #666
    transition: transform 0.3s ease

.faqitem.open .faqicon 
    transform: rotate(180deg)

.faqdescription 
    max-height: 0
    overflow: hidden
    transition: max-height 0.3s ease, padding 0.3s ease

.faqdescription.visible
    background: #fff
    color: #000
    max-height: 150px
    padding: 15px 20px
    +dark
        background-color: #2c2c2c
        color: #fff

.faqdescription p
    background: #fff
    margin: 0
    color: #555
    line-height: 1.6
    +dark
        background-color: #2c2c2c
        color: #fff