@import ../../../styles/helpers

.head
    display: flex
    margin-bottom: 80px 0 0
    +t
        display: block
        margin-bottom: 48px

.stage
    width: 352px
    +t
        width: 100%
        margin-bottom: 20px

.wrap
    flex-shrink: 0
    +d
        width: 500px
    +t
        width: 100%

.title
    margin-bottom: 20px

.info
    max-width: 450px
    margin-bottom: 64px
    +body-2
    color: $n3
    +t
        margin-bottom: 48px
    +dark
        color: $n7

.nav
    display: flex
    +m
        display: none

.link
    display: flex
    padding: 6px 12px
    border-radius: 14px
    background: none
    +button-2
    color: $n4
    transition: all .2s
    &:hover
        color: $n3    +dark
        color: $n7
        +dark
            color: $n6
    &.active
        background: $n3
        color: $n8
        +dark
            background: $n6
            color: $n2
    &:not(:last-child)
        margin-right: 12px

.dropdown
    display: none
    +m
        display: block
    
.dropdownHead
    background: transparent

.item
    &:not(:last-child)
        margin-bottom: 80px

.customtable
    border: 1px solid #6C757D
    border-collapse: collapse

.customtable th,
.customtable td
    border: 1px solid #6C757D
    padding: 12px
    text-align: left
    font-size: 14px
    line-height: 22px
    +dark
        color: $n8
        background: $n2

.customtable th 
    font-weight: normal
    padding-left: 32px
    +dark
        color: $n8
        background: $n2

.customtable .rowlight 
    background-color: #F9F9F9
    color: #414747
    +dark
        color: $n8
        background: $n2

.customtable .rowdark
    background-color: #1a1a1a
    color: #fff
    +dark
        color: $n8
        background: $n2

.customtable .rowlight th,
.customtable .rowdark th 
    padding-left: 32px
    +dark
        color: $n8
        background: $n2

.text
    margin-bottom: 24px
    +body-2
    color: $n4
    span
        color: $p4

.thirdcontainer
    display: flex
    gap: 10px
    margin-top: 20px

.leftcolumn,
.middlecolumn,
.rightcolumn
    flex: 1

.card
    border-radius: 12px
    padding: 20px
    border: 1px solid #ccc
    cursor: pointer
    margin-bottom: 16px
    height: 100%
    +dark
        color: $n8
        background: $n2

.cardcontent
    text-align: left
    border-bottom: 1px solid #ccc
    padding-bottom: 10px

.cardcontentsmall
    text-align: left
    padding-bottom: 10px

.leftcolumn 
    display: flex
    flex-direction: column
    gap: 16px

.middlecolumn
    display: flex
    flex-direction: column
    gap: 1px

.rightcolumn 
    display: flex
    flex-direction: column
    gap: 1px

.bigcard 
    flex: 3

.smallcard
    flex: 1

.bigcardcard
    border-radius: 12px
    padding: 20px
    border: 1px solid #ccc
    cursor: pointer
    margin-bottom: 16px
    height: 100%
    +dark
        color: $n8
        background: $n2

.smallcardcard
    border-radius: 12px
    padding: 20px
    border: 1px solid #ccc
    cursor: pointer
    margin-bottom: 16px
    +dark
        color: $n8
        background: $n2

.titleHeading
    margin-right: auto
    +m
        margin: 0 0 16px
        font-size: 32px
        line-height: 40px

.secondcardcontent
    text-align: left
    padding: 10px 0

.details
    padding: 48px 0 16px 0
    text-align: center

.note
    padding: 20px 0 0 0    

.cardimage 
    text-align: center
    padding-top: 10px
    img
        max-width: 100%
        border-radius: 8px  

.dashboardContainer
    display: flex
    justify-content: space-between
    background-color: #f9f9f9
    +dark
        background: $n1
    +m
        flex-direction: column

.profitInfo
    background-color: #fff
    border-radius: 10px
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1)
    padding: 40px
    width: 49%
    +dark
        background: $n1
    +m
        width: 100%
        margin-bottom: 20px
    

.highlight
    color: #007bff
    font-weight: bold

.stakingInfo
    background-color: #fff
    border-radius: 10px
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1)
    padding: 40px
    width: 49%
    +dark
        background: $n1
    +m
        width: 100%
        margin-bottom: 20px

.amount
    margin-top: 24px
    text-align: center

.coinImage 
    margin-right: 10px

.details p
    margin: 5px 0

.actions
    display: flex
    gap: 10px
    margin-top: 20px

.actions button 
    flex: 1

.stakeBtn
    background-color: #000
    color: #fff

.redeemBtn
    padding: 10px 20px
    background-color: #ddd
    color: $n1
    cursor: not-allowed
    border-radius: 24px
    +dark
        color: $n1

.stakeInfo
    color: #57585c
    font-size: 11px
    margin-top: 10px
    span
        color: #03AAC7
        display: inline-flex

.stakeTitle
    text-align: left
    font-size: 20px
    line-height: 28px
    font-weight: 600

.profitAmount
    font-weight: 600
    font-size: 32px
    line-height: 44px

.stakedAmount
    margin-top: 20px
    margin-bottom: 8px
    display: flex
    justify-content: space-between

.availableAmount
    margin-bottom: 8px
    display: flex
    justify-content: space-between

.redeem
    display: flex
    justify-content: space-between

.value
    font-weight: 600
    font-size: 16px
    line-height: 22px

.key
    color: #57585c
    font-size: 12px
    line-height: 22px

.profitHeader
    justify-content: space-between
    display: flex
    margin-bottom: 38px

.myProfitText
    font-size: 16px
    line-height: 22px
    font-weight: 600

.account
    display: flex
    top: -4px
    position: relative

.goToAccount
    align-items: center
    cursor: pointer
    display: flex

.goToHistory
    align-items: center
    cursor: pointer
    display: flex
    margin-left: 12px

.totalEarningFirstSpan
    color: #57585c
    font-weight: 400
    font-size: 14px
    line-height: 22px
    display: block
    margin-bottom: 12px
    +dark
        color: #fff

.totalEarningSecondSpan
    color: #151717
    font-weight: 600
    font-size: 24px
    line-height: 34px
    display: flex
    margin-bottom: 10px
    +dark
        color: #fff

.totalEarningThirdSpan
    color: #8e8e92
    font-weight: 400
    font-size: 14px
    line-height: 22px
    display: block

.earnings
    display: grid
    grid-template-columns: repeat(2, minmax(0, 1fr))

.currentTier
    font-weight: 600
    font-size: 16px
    line-height: 22px
    margin-top: 32px
    margin-bottom: 4px

.perks
    font-weight: 600
    font-size: 16px
    line-height: 22px
    margin-top: 24px
    margin-bottom: 12px

.feeWithdrawal
    color: #57585c
    font-weight: 400
    font-size: 12px
    line-height: 22px
    span    
        color: #151717
        font-weight: 600
        font-size: 16px
        line-height: 22px
        margin-inline-start: 8px
    
.apy
    color: #57585c
    font-weight: 400
    font-size: 12px
    line-height: 22px
    margin-top: 9px
    span    
        color: #151717  
        font-weight: 600
        font-size: 16px
        line-height: 22px
        margin-inline-start: 8px
        +dark
            color: #fff