@import ../../../styles/helpers

.successfully
    padding-top: 40px

.title
    margin-bottom: 32px
    text-align: center

.info
    max-width: 280px
    margin: 0 auto 32px
    text-align: center
    +body-bold-2
    span
        color: $p4

.list
    display: flex
    flex-wrap: wrap
    padding: 24px
    border-radius: 12px
    border: 1px solid $n6
    +dark
        border-color: $n3

.item
    &:first-child
        margin-right: auto

.category
    margin-bottom: 10px
    color: $n4

.content
    font-weight: 500
    color: $n2
    +dark
        color: $n8

.btns
    text-align: center
    margin: 32px -8px 0

.button
    flex: 0 0 calc(50% - 16px)
    width: calc(50% - 16px)
    margin: 0 8px

.subTitle
    margin-bottom: 32px
    text-align: center
    font-family: "DM Sans", sans-serif

.danger
    color: red

.box
    margin-bottom: 8px

.messageContainer
    display: flex
    flex-direction: column
    gap: 20px
    max-width: 600px
    font-family: Arial, sans-serif
    line-height: 1.6

    .messageBox, .instructionsBox, .supportBox
        background-color: #f9f9f9
        border-radius: 8px
        padding: 15px 20px
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1)
        color: #333

    .messageBox
        border-left: 4px solid #22767d
        font-weight: 600

    .instructionsBox
        border-left: 4px solid #28a745

    .supportBox
        border-left: 4px solid #007bff
        color: #555

        a
            color: #007bff
            text-decoration: none

            &:hover
                text-decoration: underline

    +dark
        .messageBox, .instructionsBox, .supportBox
            background-color: #2c2c2c
            color: #fff

        .supportBox a
            color: #66b3ff

.boxModal
    display: flex
    flex-direction: column
    gap: 20px
    max-width: 600px
    margin: 20px auto
    font-family: Arial, sans-serif

    .mainContent
        background-color: #f9f9f9
        border: 1px solid #ddd
        border-radius: 8px
        padding: 20px
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1)
        color: #333
        line-height: 1.6
        p
            margin-bottom: 15px

        +dark
            background-color: #2c2c2c
            color: #fff

.noteContainer
    background-color: #fff4e5
    border-left: 4px solid #ff9800
    border-radius: 8px
    padding: 15px 20px
    margin: 2px 0
    font-size: 14px
    color: #333
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1)

    strong
        color: #ff9800

    +dark
        background-color: #3a3a3a
        border-color: #ff9800
        color: #fff

        strong
            color: #ffa726
