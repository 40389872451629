@import ../../styles/helpers

.wallet
    display: flex
    padding: 4px
    background: $n7
    +t
        display: block
        padding: 32px 16px 16px
    +dark
        background: $n1

.sidebar
    overflow-x: hidden
    overflow-y: auto  
    display: flex
    flex-direction: column
    flex: 0 0 256px
    width: 256px
    height: calc(100vh - 88px)
    padding-top: 24px
    background: $n8
    border-radius: 4px
    +d
        flex: 0 0 180px
        width: 180px
    +t
        width: 100%
        height: auto
        margin-bottom: 16px
        padding-top: 0
        background: none
    +dark
        background: #18191D
        +t
            background: none

.wrapper
    flex-grow: 1
    height: calc(100vh - 88px)
    padding-left: 4px
    overflow: auto
    +t
        height: auto
        padding-left: 0

.group
    margin-bottom: auto
    +t
        position: relative
        z-index: 5
        margin: 32px 0 0
        &.active
            .top
                box-shadow: inset 0 0 0 2px $p1
                &:after
                    transform: translateY(-50%) rotate(180deg)
            .menu
                visibility: visible
                opacity: 1

.top
    display: none
    +t
        position: relative
        display: flex
        align-items: center
        height: 48px
        padding: 0 64px 0 0
        border-radius: 12px
        background: $n8
        box-shadow: inset 0 0 0 2px $n6
        font-weight: 500
        transition: all .2s
        +dark
            background: #18191D
            box-shadow: inset 0 0 0 2px $n3
        &:after
            content: ""
            position: absolute
            top: 50%
            right: 16px
            width: 24px
            height: 24px
            transform: translateY(-50%)
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill-rule='evenodd' d='M16.207 9.793a1 1 0 0 0-1.414 0L12 12.586 9.207 9.793a1 1 0 0 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z' fill='%23777e91'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto
            transition: transform .2s

.menu
    display: flex
    flex-direction: column
    +t
        position: absolute
        top: calc(100% + 4px)
        left: 0
        right: 0
        box-shadow: 0px 16px 64px -32px rgba(31, 47, 70, 0.15)
        border-radius: 12px
        background: $n8
        overflow: hidden
        visibility: hidden
        opacity: 0
        transition: all .2s
        +dark
            background: $n3

.item
    display: flex
    align-items: center
    height: 48px
    +button-2
    color: $n4
    transition: color .2s
    svg
        margin: 0 14px 0 22px
        fill: $n4
        transition: fill .2s
    &:hover,
    &.active
        color: $n2
        +t
            background: $n6
        svg
            fill: $n2
        +dark
            color: $n8
            +t
                background: $n2
            svg
                fill: $n8
    &:not(:last-child).separator
        position: relative
        margin-bottom: 41px
        +t
            margin-bottom: 0
        &:after
            content: ""
            position: absolute
            top: calc(100% + 20px)
            left: 0
            right: 0
            height: 1px
            background: $n6
            +dark
                background: $n2
            +t
                display: none
    &.separator ~ &
        +t
            display: none

.bg
    flex-shrink: 0
    width: 12px
    height: 12px
    margin: 0 18px 0 26px
    border-radius: 4px

.btns
    margin-top: 24px
    padding: 16px
    +t
        display: flex
        margin: 0 -6px
        padding: 0
        order: -1

.button
    width: 100%
    +t
        flex: 1
        margin: 0 6px
    &:last-child
        display: flex
        +t
            display: flex
    &:not(:last-child)
        margin-bottom: 12px
        +t
            margin-bottom: 0