@import ../../../styles/helpers

.wrap
    position: relative
    z-index: 3
    +t
        max-width: 350px
    +m
        max-width: 100%
        margin-bottom: 16px
    +dark
        color: #fff

.main
    position: relative
    background: #f4f5f7
    +dark
        background: $n3

.container
    align-items: center
    min-height: 500px
    padding-top: 20px
    padding-bottom: 20px
    +d
        padding-top: 80px
    +m
        display: block
        min-height: auto
        padding-top: 32px
        padding-bottom: 16px
    +dark
        color: $n1

.line
    display: flex
    align-items: center
    padding: 0 32px
    +m
        display: none

.form
    position: relative
    flex-shrink: 0
    width: 256px
    margin-right: auto

.input
    width: 100%
    height: 40px
    padding: 0 40px 0 14px
    border-radius: 20px
    background: none
    border: 2px solid $n6
    +poppins
    +caption-2
    color: $n2
    transition: border-color .2s
    +dark
        border-color: $n3
        color: $n8
    +placeholder
        color: $n4
    &:focus
        border-color: $n4

.result
    position: absolute
    top: 0
    right: 0
    bottom: 0
    width: 40px
    svg
        fill: $n4
        transition: fill .2s
    &:hover
        svg
            fill: $p1

.link
    display: flex
    align-items: center
    +poppins
    +caption-2
    font-weight: 500
    color: $n4
    transition: color .2s
    svg
        margin-left: 4px
        fill: $n4
        transition: fill .2s
    &:hover
        color: $n2
        svg
            fill: $n2
        +dark
            color: $n8
            svg
                fill: $n8

.row
    display: flex
    border-bottom: 1px solid $n7
    +dark
        border-color: $n2

.col
    flex: 1
    padding: 16px
    +caption-bold-2
    color: $n3
    +dark
        color: $n6
    &:first-child
        padding-left: 32px
        +m
            padding-left: 16px
    &:last-child
        padding-right: 32px
        +m
            padding-right: 16px
    &:not(:first-child)
        text-align: right
    &:not(:first-child):not(:nth-child(2))
        +m
            display: none
            .button
                position: absolute

.btns
    margin-top: 64px
    text-align: center
    +m
        margin-top: 48px

.customImage
    width : 300px

.customButton
    margin: 15px 0px
    text-align: center
    +m
        margin-top: 48px

.list
    background-color: #fff
    border-radius: 10px
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1)
    padding: 40px
    +dark
        background: $n1
    +m
        width: 100%
        margin-bottom: 20px

.stakeTitle
    text-align: left
    font-size: 20px
    line-height: 28px
    font-weight: 600
    +dark
        color: #fff

.button
    margin : 20px 20px 0 0
    height: 32px
    +m
        flex-grow: 1
        padding: 0
    &:not(:last-child)
        margin-right: 8px
    
.nav
    display: flex
    margin-right: auto

.link
    display: flex
    padding: 12px
    border-radius: 14px
    background: none
    +button-2
    color: $n4
    transition: all .2s
    &:hover
        color: $n8
        +dark
            color: $n6
    &.active
        background: $n6
        color: $n2
        +dark
            background: $n3
            color: $n8
    &:not(:last-child)
        margin-right: 16px