@import ../../../styles/helpers

.successfully
    padding-top: 40px

.title
    margin-bottom: 32px
    text-align: center

.info
    max-width: 280px
    margin: 0 auto 12px
    text-align: center
    +body-bold-2
    span
        color: $p4

.list
    display: flex
    flex-wrap: wrap
    padding: 24px
    border-radius: 12px
    border: 1px solid $n6
    +dark
        border-color: $n3

.item
    &:first-child
        margin-right: auto

.category
    margin-bottom: 10px
    color: $n4

.content
    font-weight: 500
    color: $n2
    +dark
        color: $n8

.btns
    display: flex
    margin: 12px 8px 0

.button
    flex: 0 0 calc(50% - 16px)
    width: 100%
    margin: 10px 8px


.modaloverlay
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: rgba(0, 0, 0, 0.4)
  display: flex
  align-items: center
  justify-content: center
  z-index: 1000

.modal
  background: #ffffff
  padding: 20px 20px 30px
  border-radius: 8px
  width: 350px
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15)
  position: relative
  font-family: Arial, sans-serif

.closebtn
  position: absolute
  top: 10px
  right: 10px
  background: none
  border: none
  font-size: 20px
  color: #666
  cursor: pointer

h2
  font-size: 20px
  color: #333
  font-weight: bold
  text-align: center
  margin: 0

.exchangeinfo
  display: flex
  align-items: center
  justify-content: space-around
  margin-top: 15px

.currency
  text-align: center

.currency img
  width: 36px
  height: 36px

.currency span
  display: block
  font-size: 12px
  color: #777

.currency h3
  margin: 5px 0 0
  font-size: 18px
  font-weight: 600

.arrow 
  font-size: 24px
  color: #333

.details
  margin-top: 20px
  border-top: 1px solid #ddd
  padding-top: 15px

.detail
  display: flex
  justify-content: space-between
  margin: 8px 0
  font-size: 14px
  color: #555

.detail span:last-child
  color: #333
  font-weight: 500

.warning
    background: #fff8e5
    padding: 10px
    border-radius: 4px
    color: #d98000
    font-size: 13px
    margin-top: 20px
    display: flex
    align-items: center

.warning p 
    margin: 0
