@import ../../../styles/helpers

.currency
    width: 100%
    max-height: 795px
    overflow: auto
    padding: 0px 11px 11px 11px
    border-radius: 4px
    background: $n8
    +dark
        background: #17181B

.nav
    display: flex
    margin-bottom: 4px
    padding: 5px 0px
    width: 100%

.link
    display: flex
    padding: 6px 12px
    border-radius: 14px
    background: none
    +button-2
    color: $n4
    transition: all .2s
    &:hover
        color: $n3
        +dark
            color: $n6
    &.active
        background: $n6
        color: $n2
        +dark
            background: $n3
            color: $n8
    &:not(:last-child)
        margin-right: 8px

.form
    position: relative
    margin-bottom: 4px

.input
    width: 100%
    height: 30px
    padding: 0 40px 0 14px
    border-radius: 8px
    background: none
    border: 2px solid $n6
    +poppins
    +caption-2
    color: $n2
    transition: border-color .2s
    +dark
        border-color: $n3
        color: $n8
    +placeholder
        color: $n4
    &:focus
        border-color: $n4

.result
    position: absolute
    top: 0
    right: 0
    bottom: 0
    width: 40px
    svg
        fill: $n4
        transition: fill .2s
    &:hover
        svg
            fill: $p1

.table
    display: table
    width: 100%
    
.row
    display: table-row
    color: $n4
    +caption-2
    font-weight: 500

.col
    display: table-cell
    padding: 3px
    &:first-child
        padding-left: 0
    &:last-child
        padding-right: 0
        text-align: right

.line
    display: inline-flex
    align-items: center

.favorite
    position: relative
    top: -2px
    margin-right: 4px

.info
    span
        color: $n4

.positive
    color: $p4

.negative
    color: $p3

.market
    color: $n2
    +dark
        color: $n8

.stickyHeaderNav
    position: sticky
    top: 0
    background: $n8
    +dark
        background: $n1
    
.stickySearchBar
    position: sticky
    top: 35px
    background: $n8

.rowOne
    display: table-row
    position: sticky
    top: 68px
    background: $n8
    &:first-child
        .col
            padding-bottom: 4px
            +caption-bold-2
            color: $n4
    &:not(:first-child)
        .col
            +caption-2
            font-weight: 500

.customRow
    display: flex
    
.customCol
    flex: 1
    &:first-child
        text-align: left
    &:last-child
        text-align: right
    &:nth-child(2)
        text-align: center
        
.activeColumn
    color: #22767d

.line
    margin-right: 5px

.foot
    overflow-x: auto

.favoriteLine
    svg 
        fill: #000
    +dark
        svg 
            fill: #fff
    