@import ../../styles/helpers

.inner
    margin-top: 4px
    padding: 6px
    border-radius: 4px
    background: $n8
    +dark
        background: #17181B

.dropdown
    display: none
    +m
        display: block
        width: 100%
        margin-bottom: 16px

.nav
    display: flex
    margin-bottom: 4px
    +m
        display: none

.link
    display: flex
    padding: 6px 12px
    border-radius: 14px
    background: none
    +button-2
    color: $n4
    transition: all .2s
    &:hover
        color: $n3
        +dark
            color: $n6
    &.active
        background: $n6
        color: $n2
        +dark
            background: $n3
            color: $n8
    &:not(:last-child)
        margin-right: 16px

.table
    display: table
    padding: 8px 24px 8px 10px
    margin-top: 8px
    width: 100%
    border-radius: 16px
    background-color: gainsboro
    
    +dark
            background-color: $n3

.row
    display: table-row
    &:first-child
        .col
            padding-bottom: 4px
            +caption-bold-2
            color: $n4
    &:not(:first-child)
        .col
            +caption-2
            font-weight: 500

.col
    display: table-cell
    padding: 4px
    font-size: 11px !important
    &:first-child
        padding-left: 0
    &:nth-child(3)
        +m
            padding-right: 0
            text-align: right

.positive
    color: $p4

.negative
    color: $p3

.button
    height: 20px
    padding: 0 8px
    font-size: 11px    

.conv
    font-size: 8px    