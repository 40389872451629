.container
    width: 100%
    max-width: 1280px
    margin: 0 auto
    padding: 0 80px
    +t
        padding: 0 40px
    +m
        padding: 0 32px

.containerEx
    width: 100%
    max-width: 1880px
    margin: 0 auto
    padding: 0 80px
    +t
        padding: 0 40px
    +m
        padding: 0 32px