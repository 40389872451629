@import ../../../styles/helpers

.item
    flex: 0 0 calc(33.333% - 36px)
    width: calc(33.333% - 36px)
    margin: 0 18px
    padding: 10px
    color: $n2
    +t
        flex: 0 0 250px
        width: 250px
        margin: 0
        padding: 24px 0
    +m
        flex: 0 0 220px
        width: 220px
    +dark
        color: $n8
    &:hover
        .price
            color: $p1