@import ../../styles/helpers

.title
    margin-bottom: 24px
    +body-bold-1

.text,
.info
    margin-bottom: 12px

.text
    color: $n4

.info
    +body-bold-2

.code
    padding: 12px
    background: $n6
    border-radius: 4px
    text-align: center
    +body-bold-2
    +dark
        background: $n3

.btns
    margin-top: 24px
    text-align: center
    .button
        min-width: 184px
        margin-top: 30px

.label
    text-align: center
    font-size : 14px
    font-weight: 600
    color: $n4



.labelContainer
    margin-top: 100px

.heading
    font-size: 100px
    color: green