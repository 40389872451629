@import ../../../styles/helpers

.form
    max-width: 380px
    margin: 0 auto

.title
    margin-bottom: 32px
    text-align: center

.field
    margin-bottom: 12px

.button
    width: 100%

.danger
    color: red
    font-size: 12px