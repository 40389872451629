@import ../../../styles/helpers


.item
    position: relative
    display: flex
    padding: 1px 16px
    +caption-2
    font-weight: 500
    &.negative
        .line
            background: rgba($p3, .15)
        .price
            color: $p3
    &.positive
        .line
            background: rgba($p4, .15)
        .price
            color: $p4
    &:not(:last-child)
        margin-bottom: 4px
    &:nth-child(n+10)
        +d
            display: none
        +t
            display: flex

.price
    font-weight: 600

.price,
.amount
    flex-shrink: 0
    width: 70px

.amount
    text-align: right

.total
    flex-grow: 1
    text-align: right          