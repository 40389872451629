@import ../../../styles/helpers


.row
    display: flex
    margin: 8px -8px
    +m
        display: block
        margin: 0

.col
    position: relative
    flex: 0 0 calc(50% - 16px)
    width: calc(50% - 16px)
    margin: 0 8px
    +m
        width: 100%
        margin: 0
        &:not(:last-child)
            margin-bottom: 16px