@import ../../../styles/helpers

.main
    position: relative

.container
    display: flex
    align-items: center
    min-height: 640px
    padding-top: 100px
    +d
        padding-top: 80px
    +m
        display: block
        min-height: auto
        padding-top: 32px
        padding-bottom: 16px

.wrap
    position: relative
    z-index: 3
    max-width: 545px
    +t
        max-width: 350px
    +m
        max-width: 100%
        margin-bottom: 16px

.stage
    margin-bottom: 12px

.title
    margin-bottom: 20px

.text
    margin-bottom: 24px
    +body-2
    color: $n4
    span
        color: $p4

.form
    max-width: 300px
    +m
        max-width: 100%
    
.bg
    position: absolute
    top: 90px
    right: calc(48% - 730px)
    width: 785px
    pointer-events: none
    +d
        right: calc(50% - 670px)
        width: 700px
    +m
        position: static
        width: 100%
        padding-left: 16px
    img
        width: 100%

.referralProgram
    margin: 0px auto 20px
    position: relative
    border: 2px solid green
    border-radius: 10px
    padding: 20px
    z-index: 3
    max-width: 545px
    background-color: #18191D
    +t
        max-width: 350px
    +m
        max-width: 100%
        margin-bottom: 16px

.referral
    margin: 20px auto
    z-index: 3
    max-width: 545px
    width: 100%
    background-color: $n3
    display: flex
    align-items: center
    padding: 0 10px
    box-sizing: border-box

.leftText 
    padding: 10px 0px
    color: white
    display: inline-block
    flex: 1

.rightText
    padding: 10px 0px
    color: white
    display: inline-block

.btnClass
    margin-left: 3px
    background: $n6
    +dark
        border-color: $n2
        +t
            border-color: $n3
