@import ../../../styles/helpers

.stage
    +caption-2
    color: $n3
    +dark
        color: $n6

.title
    margin-bottom: 12px
    span
        color: $p4

.email
    display: flex
    align-items: center
    +body-bold-2
    svg
        margin-right: 12px
        fill: $n2
        +dark
            fill: $n8

.item
    margin-top: 48px
    padding-top: 48px
    border-top: 1px solid $n6
    +m
        margin-top: 24px
        padding-top: 24px
    +dark
        border-color: $n3
    &:last-child
        .button
            +m
                width: 100%

.subtitle
    margin-bottom: 24px
    +body-bold-1

.info
    margin-bottom: 24px
    +body-2
    color: $n3
    +dark
        color: $n6

.group
    margin-bottom: 24px

.box
    position: relative
    .field
        position: relative
        padding-right: 36px
    .label
        color: $n2
        +dark
            color: $n4
    .input
        padding-right: 82px
        border-color: $n7
        background: $n7
        +dark
            background: $n2
            border-color: $n2
        &:focus
            border-color: $p4
            background: transparent
    &:not(:last-child)
        margin-bottom: 24px

.remove
    position: absolute
    right: 0
    bottom: 12px
    svg
        fill: $n4
        transition: fill .2s
    &:hover
        svg
            fill: $n2

.category
    position: absolute
    right: 48px
    bottom: 11px

.row
    display: flex
    margin: 0 -8px
    +m
        display: block
        margin: 0
   
.col
    position: relative
    flex: 0 0 calc(50% - 16px)
    width: calc(50% - 16px)
    margin: 0 8px
    +m
        width: 100%
        margin: 0
        &:not(:last-child)
            margin-bottom: 16px
   

    .field
        flex: 0 0 calc(50% - 16px)
        width: calc(50% - 16px)
        margin: 0 8px
        +m
            width: 100%
            margin: 0
            &:not(:last-child)
                margin-bottom: 16px
        +dark
            background: $n2
            border-color: $n2       
    &:not(:last-child)
        margin-bottom: 16px
