@import ../../../styles/helpers

.main
    padding: 12px
    background: $n8
    border-radius: 4px
    +m
        padding: 16px
    +dark
        background: #17181B

.title
    margin-bottom: 20px

.list
    display: flex
    justify-content: space-between
    align-items: flex-start
    +m
        flex-wrap: wrap
        margin-top: -24px

.item
    +m
        flex: 0 0 50%
        width: 50%
        margin-top: 24px

.info
    margin-bottom: 4px
    font-weight: 500
    color: $n3
    +d
        font-size: 12px
    +dark
        color: $n7

.line
    display: flex
    align-items: center

.currency
    +body-bold-1
    +d
        font-size: 16px
    +m
        font-size: 14px

.category
    margin-left: 8px

.price
    +body-2
    color: $n4
    +d
        font-size: 12px

