@import ../../../styles/helpers

.item
    transition: background .2s
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
    &:hover,
    &.active
        background: #F8F8F9
        +dark
            background: $n2
    &.active
        .btns
            display: flex
    &:not(:last-child)
        border-bottom: 1px solid $n6
        +dark
            border-color: $n2
            +t
                border-color: $n3

.row
    display: flex
    cursor: pointer
    padding: 8px 0 8px

.col
    flex: 1
    padding: 0 16px
    &:first-child
        padding-left: 8px
        +m
            padding-left: 16px
    &:last-child
        padding-right: 16px
        +m
            padding-right: 16px
    &:not(:first-child)
        text-align: right
    &:not(:first-child):not(:nth-child(2))
        +m
            display: none