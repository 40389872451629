@import ../../../../styles/helpers

.table
    display: table
    width: 100%
    +t
        display: block

.row
    display: table-row
    color: $n2
    +t
        position: relative
        display: block
    &:first-child
        +t
            display: none
        .col
            padding: 0 6px 10px
            border-bottom: 1px solid $n6
            +caption-bold-2
            color: $n4
            +dark
                border-color: $n2
            svg
                margin-left: 4px
                fill: $n4
    &:not(:first-child)
        transition: background .2s
        .col
            vertical-align: middle
            font-weight: 500
            &:first-child
                border-radius: 12px 0 0 12px
                color: $n4
                +t
                    border-radius: 0
            &:last-child
                border-radius: 0 12px 12px 0
                +t
                    border-radius: 0
        &:hover
            background: $n7
            +dark
                background: $n2
            .button
                display: inline-flex
            .chart
                display: none
    &:not(:last-child)
        +t
            margin-bottom: 24px
            padding-bottom: 20px
            border-bottom: 1px solid $n6
            +dark
                border-color: $n3

.col
    display: table-cell
    padding: 6px
    text-align: right
    font-weight: 500
    +t
        display: flex
        align-items: center
        margin-bottom: 4px
        padding: 0
    +dark
        color: $n8
    &:first-child,
    &:nth-child(2)
        text-align: left
    &:nth-child(2)
        +t
            margin-bottom: 16px
    &:nth-last-child(2)
        +d
            display: none
    &:first-child
        font-size: 12px
        +t
            display: none
    &:last-child
        +t
            margin-bottom: 0

.line
    display: inline-flex
    align-items: center
    +caption-bold-2

.favorite
    position: relative
    top: -1px
    margin-right: 10px

.item
    display: flex
    align-items: center

.icon
    flex-shrink: 0
    width: 32px
    margin-right: 12px
    img
        max-width: 100%

.subtitle
    margin-right: 4px

.currency
    font-weight: 400
    color: $n4

.positive
    color: $p4

.negative
    color: $p3

.button
    width: 96px
    +d
        display: inline-flex
    +t
        position: absolute
        top: -6px
        right: 0

.chart
    display: inline-block
    width: 96px
    height: 40px
    +d
        display: none

.label
    display: none
    +t
        display: block
        margin-right: auto
        +caption-bold-2
        color: $n4
.form
    position: relative
    margin-bottom: 12px

.input
    width: 100%
    height: 40px
    padding: 0 40px 0 14px
    border-radius: 8px
    background: none
    border: 2px solid $n6
    +poppins
    +caption-2
    color: $n2
    transition: border-color .2s
    +dark
        border-color: $n3
        color: $n8
    +placeholder
        color: $n4
    &:focus
        border-color: $n4

.result
    position: absolute
    top: 0
    right: 0
    bottom: 0
    width: 40px
    svg
        fill: $n4
        transition: fill .2s
    &:hover
        svg
            fill: $p1

.activeColumn
    color: #22767d

.foot
    display: flex
    align-items: center 
    padding: 12px 20px
    border-top: 1px solid $n6
    +m
        display: block
    +dark
        border-color: $n2

.nav
    display: flex
    margin-right: auto
    +m
        display: none

.link
    display: flex
    padding: 6px 12px
    border-radius: 14px
    background: none
    +button-2
    color: $n4
    transition: all .2s
    &:hover
        color: $n3
        +dark
            color: $n6
    &.active
        background: $n3
        color: $n8
        +dark
            background: $n6
            color: $n2
    &:not(:last-child)
        margin-right: 12px
        +t
            margin-right: 6px

.favorite
    background: none
    border: none
    +dark
        svg 
            fill: #fff
    &.active 
        svg 
            stroke: green