@import ../../../styles/helpers

.title
    margin-bottom: 12px
    margin-top: 32px
    text-align: center

.info
    margin-bottom: 12px
    text-align: center
    +caption-2
    color: $n4

.code
    display: flex
    margin: 0 -8px

.number
    flex: 0 0 calc(17% - 16px)
    width: calc(17% - 16px)
    margin: 0 6px
    input
        width: 100%
        height: 70px
        border-radius: 8px
        border: 1px solid $n7
        background: $n7
        text-align: center
        +dm-sans
        font-size: 32px
        font-weight: 600
        color: $n2
        transition: all .2s
        &:focus
            border-color: $n6
            background: $n8
        +dark
            background: $n2
            border-color: $n4
            color: $n8
            &:focus
                background: none
                border-color: $n3

.button
    width: 100%
    margin-top: 12px
    +dark
        color: #fff

.subHeading
    margin-bottom: 10px
    text-align: center