@import ../../../../styles/helpers

.successfully
    padding-top: 40px

.title
    margin-bottom: 32px
    text-align: center

.info
    margin: 0 auto 32px
    text-align: center
    +body-bold-2
    span
        color: $p4

.list
    display: flex
    flex-wrap: wrap
    padding: 24px
    border-radius: 12px
    border: 1px solid $n6
    +dark
        border-color: $n3

.item
    &:first-child
        margin-right: auto

.category
    margin-bottom: 10px
    color: $n4

.content
    font-weight: 500
    color: $n2
    +dark
        color: $n8

.btns
    display: flex
    margin: 32px -8px 0

.button
    flex: 0 0 calc(50% - 16px)
    margin: 10px 8px
    width: 100%

.danger
    color: red

.box
    position: relative
    .button
        position: absolute
        top: 36px
        right: 16px
        height: 24px
        padding: 0 16px

.availableAmount
    margin-bottom: 4px
    span
        color: #57585c
        font-size: 12px
        cursor: pointer
        +dark
            color: #fff

.stakeContent
    color: #8e8e92
    font-size: 12px
    line-height: 18px
    padding: 8px
    background: #f4f5f7
    border-radius: 5px
    flex-direction: column
    display: flex
    box-sizing: border-box
    margin-top: 32px
    margin-bottom: 24px
    div
        margin-bottom: 6px
        position: relative
    +dark
        color: #fff
        background: $n3

.detailsContainer
    display: flex
    flex-direction: column
    gap: 15px

.title
    font-size: 24px
    font-weight: 600
    color: #333
    margin-bottom: 20px
    +dark
        color: #fff

.detailsGrid
    display: grid
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr))
    gap: 10px

.detail
    background: #f9f9f9
    padding: 15px
    border-radius: 8px
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1)
    display: flex
    justify-content: space-between
    +dark
        background: $n3

.label
    font-weight: 500
    color: #555
    +dark
        color: #fff

.value
    font-weight: 400
    color: #333
    +dark
        color: #fff
