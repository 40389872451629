@import ../../styles/helpers

.footercontainer 
    .connectionstatus
        path
            fill: #28a745

        .status-text
            margin-left: 5px

    .priceticker 
        overflow: hidden
        white-space: nowrap
        position: relative

        .marquee
            display: inline-block
            animation: marquee 600s linear infinite
            animation-play-state: running
            &:hover
                animation-play-state: paused
                cursor: pointer

        .tickeritem
            display: inline-block
            padding: 0 15px
            a
                text-decoration: none
                color: #000

        .changepositive
            margin-left: 5px
            color: #28a745 
            font-size: 12px 

        .changeNegative 
            color: red
            font-size: 12px

        .lastprice
            margin-left: 10px
            color: #000 
            font-size: 12px 
            +dark   
                color: #fff

@keyframes marquee
    0%
        transform: translateX(0%) 
    100% 
        transform: translateX(-50%)
