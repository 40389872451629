@import ../../../styles/helpers


.row
    display: flex
    margin: 8px -8px
    +m
        display: block
        margin: 0

.col
    flex: 1
    padding: 2px
    +caption-bold-2
    color: $n3
    +dark
        color: $n6
    &:first-child
        padding-left: 32px
        +m
            padding-left: 16px
    &:last-child
        padding-right: 32px
        +m
            padding-right: 16px
    &:not(:first-child)
        text-align: right
    &:not(:first-child):not(:nth-child(2))
        +m
            display: none



            .button
             position: absolute