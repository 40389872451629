@import ../../../styles/helpers

.successfully
    padding-top: 40px

.title
    margin-bottom: 32px
    text-align: center

.info
    max-width: 280px
    margin: 0 auto 32px
    text-align: center
    +body-bold-2
    span
        color: $p4

.list
    display: flex
    flex-wrap: wrap
    +dark
        border-color: $n3

.item
    &:first-child
        margin-right: auto

.category
    margin-bottom: 10px
    color: $n4

.content
    font-weight: 500
    color: $n2
    +dark
        color: $n8

.btns
    display: flex
    margin: 32px -8px 0

.button
    flex: 0 0 calc(50% - 16px)
    width: calc(50% - 16px)
    margin: 0 8px

.subTitle
    margin-bottom: 32px
    text-align: center
    font-family: "DM Sans", sans-serif

.danger
    color: red

.box
    margin-bottom: 8px

.boxModal
    display: flex
    flex-direction: column
    gap: 20px
    max-width: 600px
    margin: 20px auto
    font-family: Arial, sans-serif

    .mainContent
        background-color: #f9f9f9
        border: 1px solid #ddd
        border-radius: 8px
        padding: 20px
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1)
        color: #333
        line-height: 1.6
        p
            margin-bottom: 15px

        +dark
            background-color: #2c2c2c
            color: #fff

    .keyPointsBox
        background-color: #f9f9f9
        border: 1px solid #ccc
        border-radius: 8px
        padding: 20px
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1)
        +dark
            background-color: #2c2c2c
            color: #fff
        
        .title
            font-size: 18px
            margin-bottom: 10px
            font-weight: 600
            color: #333
            +dark
                color: #fff
                background: #n7

        .list
            list-style-type: disc
            padding-left: 20px
            color: #555

            +dark
                color: #ddd

            li
                margin-bottom: 8px
                line-height: 1.5
