@import ../../../styles/helpers

.container
    max-width: 1440px
    padding: 0

.info
    margin: 0 auto 20px
    text-align: center
    font-weight: 500
    color: $n4

.item
    color: $n2
    transition: color .2s
    +dark
        color: $n8
    &:hover
        color: $p1
        .preview
            img
                transform: scale(1.1)

.preview
    position: relative
    display: flex
    justify-content: center
    align-items: center
    height: 296px
    overflow: hidden
    img
        max-width: 100%
        max-height: 100%
        transition: transform 1s

.category
    position: absolute
    top: 12px
    right: 12px
    z-index: 2

.body
    padding: 40px 40px 0

.title
    margin-bottom: 16px
    +body-bold-2

.content
    color: $n4

.infoNew
    text-align: center
    font-weight: 500
    color: $n1
    font-size: 40px
    +dark
        color: $n8

.buttonClass
    text-align: center
    font-weight: 500
    color: $n1

.newContainer
    margin-bottom: 20px
    max-width: 1440px
    padding: 20px 0
    background: $n7
    width: 100%
    padding: 0 auto 20px
    +dark
        background: $n2

.previewNew
    max-width: 1440px
    border: 2px solid #22767d
    border-radius: 20px
    position: relative
    display: flex
    justify-content: center
    align-items: center
    height: 257px
    overflow: hidden
    margin-bottom: 20px
    img
        max-width: 100%
        max-height: 100%
        transition: transform 1s

.categoryNew
    position: absolute
    top: 12px
    right: 12px
    z-index: 2

.firstImage
    width: 50%

.bannerText
    text-align: center
    font-weight: 900
    color: #22767d

.secondImage
    background: $n6
    width: 50%
    text-align: right
    +dark
        background: $n2