@import ../../../styles/helpers

.item
    transition: background .2s
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
    &:hover,
    &.active
        background: #F8F8F9
        +dark
            background: $n2
    &.active
        .btns
            display: flex
    &:not(:last-child)
        border-bottom: 1px solid $n6
        +dark
            border-color: $n2
            +t
                border-color: $n3

.row
    display: flex
    padding: 5px 0 5px

.col
    margin: auto
    flex: 1
    padding: 3px 
    +caption-bold-2
    color: $n3
    +dark
        color: $n6

.currency
    display: flex
    .text
        font-weight: 400

.icon
    flex-shrink: 0
    width: 32px
    margin-right: 20px
    img
        width: 100%

.info
    font-weight: 500
    font-size: 12px

.text
    font-weight: 500
    font-size: 11px
    color: $n4

.btns
    display: none
    margin-top: -10px 
    padding: 0 32px 20px
    +m
        padding: 0 16px 20px

.btnClass
    border-bottom: 1px solid $n6
    +dark
        background: $n8
        border-color: $n2
        +t
            border-color: $n3

.container
    width: 100%
    display: flex
    flex-wrap: wrap

.couponCard 
    width: 48%
    border-radius: 8px
    overflow: hidden
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1)
    background: #22767d
    color: #fff
    text-align: center
    margin-right: 10px
    margin-bottom: 10px

.couponHeader 
    background: #22767d
    padding: 15px

.couponHeader h3 
    margin: 0
    font-size: 36px
    letter-spacing: 2px
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3)

.couponContent 
    padding: 20px

.couponContent p 
    font-size: 18px
    line-height: 1.6

.couponFooter 
    background: lightgreen
    padding: 10px
    color: black
    font-weight: 700

.couponFooter span 
    font-size: 14px

.flexChildButton
    background: #FF6838