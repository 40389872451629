@import ../../../styles/helpers

.head
    display: flex
    margin-bottom: 80px 0 0
    +t
        display: block
        margin-bottom: 48px

.stage
    width: 352px
    +t
        width: 100%
        margin-bottom: 20px

.wrap
    flex-shrink: 0
    +d
        width: 500px
    +t
        width: 100%

.title
    margin-bottom: 20px

.info
    max-width: 450px
    margin-bottom: 64px
    +body-2
    color: $n3
    +t
        margin-bottom: 48px
    +dark
        color: $n7

.nav
    display: flex
    +m
        display: none

.link
    display: flex
    padding: 6px 12px
    border-radius: 14px
    background: none
    +button-2
    color: $n4
    transition: all .2s
    &:hover
        color: $n3    +dark
        color: $n7
        +dark
            color: $n6
    &.active
        background: $n3
        color: $n8
        +dark
            background: $n6
            color: $n2
    &:not(:last-child)
        margin-right: 12px

.dropdown
    display: none
    +m
        display: block
    
.dropdownHead
    background: transparent

.item
    &:not(:last-child)
        margin-bottom: 80px

.customtable
    border: 1px solid #6C757D
    border-collapse: collapse

.customtable th,
.customtable td
    border: 1px solid #6C757D
    padding: 12px
    text-align: left
    font-size: 14px
    line-height: 22px
    +dark
        color: $n8
        background: $n2

.customtable th 
    font-weight: normal
    padding-left: 32px
    +dark
        color: $n8
        background: $n2

.customtable .rowlight 
    background-color: #F9F9F9
    color: #414747
    +dark
        color: $n8
        background: $n2

.customtable .rowdark
    background-color: #1a1a1a
    color: #fff
    +dark
        color: $n8
        background: $n2

.customtable .rowlight th,
.customtable .rowdark th 
    padding-left: 32px
    +dark
        color: $n8
        background: $n2

.text
    margin-bottom: 24px
    +body-2
    color: $n4
    span
        color: $p4

.thirdcontainer
    display: flex
    gap: 10px
    margin-top: 20px

.leftcolumn,
.middlecolumn,
.rightcolumn
    flex: 1

.card
    border-radius: 12px
    padding: 20px
    border: 1px solid #ccc
    cursor: pointer
    margin-bottom: 16px
    height: 100%
    +dark
        color: $n8
        background: $n2

.cardcontent
    text-align: left
    border-bottom: 1px solid #ccc
    padding-bottom: 10px

.cardcontentsmall
    text-align: left
    padding-bottom: 10px

.leftcolumn 
    display: flex
    flex-direction: column
    gap: 16px

.middlecolumn
    display: flex
    flex-direction: column
    gap: 1px

.rightcolumn 
    display: flex
    flex-direction: column
    gap: 1px

.bigcard 
    flex: 3

.smallcard
    flex: 1

.bigcardcard
    border-radius: 12px
    padding: 20px
    border: 1px solid #ccc
    cursor: pointer
    margin-bottom: 16px
    height: 100%
    +dark
        color: $n8
        background: $n2

.smallcardcard
    border-radius: 12px
    padding: 20px
    border: 1px solid #ccc
    cursor: pointer
    margin-bottom: 16px
    +dark
        color: $n8
        background: $n2

.titleHeading
    margin-right: auto
    +m
        margin: 0 0 16px
        font-size: 32px
        line-height: 40px

.secondcardcontent
    text-align: left
    padding: 10px 0

.details
    padding: 48px 0 16px 0
    text-align: center

.note
    padding: 20px 0 0 0    

.cardimage 
    text-align: center
    padding-top: 10px
    img
        max-width: 100%
        border-radius: 8px  

.button
    margin-right: 5px