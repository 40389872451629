@import ../../../styles/helpers

.stage
    font-weight: 500
    color: $n3
    +dark
        color: $n6
        
.inner
    margin-top: 2px
    padding: 4px
    border-radius: 4px
    background: $n8
    +dark
        background: #17181B

.dropdown
    display: none
    +m
        display: block
        width: 100%
        margin-bottom: 16px

.nav
    display: flex
    margin-bottom: 16px
    +m
        display: none

.link
    display: flex
    padding: 6px 12px
    border-radius: 14px
    background: none
    +button-2
    color: $n4
    transition: all .2s
    &:hover
        color: $n3
        +dark
            color: $n6
    &.active
        background: $n6
        color: $n2
        +dark
            background: $n3
            color: $n8
    &:not(:last-child)
        margin-right: 16px

.table
    display: table
    width: 100%

.row
    display: table-row
    &:first-child
        .col
            padding-bottom: 10px
            +caption-bold-2
            color: $n4
    &:not(:first-child)
        .col
            +caption-2
            font-weight: 500

.col
    display: table-cell
    padding: 6px
    &:first-child
        padding-left: 0
        color: $n4
    &:nth-child(3)
        +m
            padding-right: 0
            text-align: right
    &:last-child
        padding-right: 0
        text-align: right
        +m
            display: none

.positive
    color: $p4

.negative
    color: $p3


.container
    width: 100%
    max-width: 400px
    margin: 20px auto
    padding: 20px
    border-radius: 8px
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)
    background-color: #f9f9f9
    +dark
        background: $n2

h2
    text-align: center
    margin-bottom: 20px
    font-size: 20px
    +dark
        color: #fff

.list
    list-style-type: none
    padding: 0
    +dark
        color: #fff

.listitem
    display: flex
    align-items: center
    margin-bottom: 12px
    padding: 10px
    border-radius: 8px
    transition: background-color 0.3s ease
    border: 1px solid #ddd

    &:hover
        background-color: #f0f8ff
        +dark
            background: none

input
    margin-right: 12px
    cursor: pointer

.label
    flex-grow: 1
    display: flex
    justify-content: space-between
    align-items: center 
    font-size: 14px

.bankDetails
    display: flex
    flex-direction: column
    .bankName
        font-weight: bold
        font-size: 16px
        color: #333
        +dark
            color: #fff
    .bankAccountNumber
        font-size: 14px
        color: #555
        margin-top: 4px
        +dark
            color: #fff

.badges
    display: flex
    align-items: center
    gap: 8px

.statusBadge
    color: #666
    background-color: #f1f1f1
    padding: 4px 8px
    border-radius: 4px
    font-size: 12px
    +dark
        color: #000
        background: $n5

.primaryBadge
    background-color: #22767D
    color: #fff
    padding: 4px 8px
    border-radius: 4px
    font-size: 12px
